// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-tsx": () => import("./../../../src/pages/about-me.tsx" /* webpackChunkName: "component---src-pages-about-me-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-articles-template-tsx": () => import("./../../../src/templates/articlesTemplate.tsx" /* webpackChunkName: "component---src-templates-articles-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-development-template-tsx": () => import("./../../../src/templates/developmentTemplate.tsx" /* webpackChunkName: "component---src-templates-development-template-tsx" */),
  "component---src-templates-life-template-tsx": () => import("./../../../src/templates/lifeTemplate.tsx" /* webpackChunkName: "component---src-templates-life-template-tsx" */),
  "component---src-templates-tags-template-tsx": () => import("./../../../src/templates/tagsTemplate.tsx" /* webpackChunkName: "component---src-templates-tags-template-tsx" */)
}

